import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { interfacesSAP } from 'src/app/services/sap.service';

@Component({
  selector: 'app-proceso',
  templateUrl: './proceso.component.html',
  styleUrls: ['./proceso.component.css']
})


export class ProcesoComponent implements OnInit {
  //datos={pages:[],data:[]};
  data = { data: [], procesados: 0, codigo: 422, mensaje: 'Sin Cambios' };
  info = { id: '', doc_params: {}, doc: {}, doc_response: {}, pendiente: 0, procesado: 0, procesando: 0, error: 0, corregir: 0, codigo: 422, mensaje: 'No hay informacion.' };

  isActive = false;
  isActiveInfo = false;
  sending = false;
  renglon = {};

  bandera = false;
  cargando = false;
  refresh = false;


  _tope = 10;
  _pages = [];
  _totalPag = 0;
  _lastPage = 0;
  _pagActive = 1;

  fecha = '';
  empresa = 1;
  consec = 0;
  timer;

  // @ViewChild('id_proceso',{static:false}) id :ElementRef;
  /* ngAfterViewInit(){
     this.id.nativeElement.value="dsdf"
   }*/


  constructor(private service: interfacesSAP) {
    var ff = new Date();
    this.fecha = ('00' + (ff.getMonth() + 1)).slice(-2) + '/' + ('00' + ff.getDate()).slice(-2) + '/' + ff.getFullYear();
    this.refresh = false;

    /* if(this.timer)
         clearInterval(this.timer);
 
     this.timer= setInterval(function() {
 
         if(document.getElementById('update')!=null)
             document.getElementById('update').click();
 
       }, 10000);/*cada 30 segundos */

  }

  async getDatos(obj) {
    this.cargando = true;
    this.bandera = false;
    await this.service.getDatosProceso(obj)
      .then((data) => {
        console.log("resss ", data);

        this.data.data = data.data;
        this.data.codigo = data.codigo;
        this.data.mensaje = data.mensaje;

        this.cargando = false;
        this.bandera = true;


      })
      .catch((e) => {

        this.data.mensaje = e;
      });
  }



  async getProcesos(params) {
    let _request = {
      info: {
        tmov: "SL",
        id_proceso: params.elements["id_proceso"].value,
        fecha_proceso: params.elements["fecha"].value
      }
    }

    if (!this.refresh) {
      await this.getDatos(_request);

      if (this.data.codigo != 200) {
        this.isActive = true;
      }
    }

  }

  async getRefreshData(params) {

    if (this.refresh)
      return;

    this.refresh = true;
    console.log("Actualizando..");
    let _request = {
      info: {
        tmov: "SL",
        id_proceso: params.elements["id_proceso"].value,
        fecha_proceso: params.elements["fecha"].value
      }
    }

    await this.service.getDatosProceso(_request)
      .then((data) => {
        this.data.data = data.data;
        this.data.codigo = data.codigo;
        this.data.mensaje = data.mensaje;
        this.refresh = false;
      })
      .catch((e) => {
        this.refresh = false;
        this.data.mensaje = e;
      });

  }

  async Cerrar() {
    this.isActive = false;
    this.isActiveInfo = false;
  }


  async viewInfo(index) {
    this.info.id = this.data.data[index].id;
    this.info.doc_params = JSON.stringify(JSON.parse(this.data.data[index].doc_params), undefined, 4);
    this.info.doc = JSON.stringify(JSON.parse(this.data.data[index].doc), undefined, 4);// this.data.data[index].doc;
    this.info.doc_response = JSON.stringify(JSON.parse(this.data.data[index].doc_response), undefined, 4); //this.data.data[index].doc_response;

    this.info.pendiente = this.data.data[index].pendiente;
    this.info.procesado = this.data.data[index].procesado;
    this.info.procesando = this.data.data[index].procesando;
    this.info.error = this.data.data[index].error;
    this.info.corregir = this.data.data[index].corregir;


    this.isActiveInfo = true;

  }


  async actualizar(index, event) {


    await this.service.getDatosProceso({
      info: {
        tmov: 'SL',
        id: this.data.data[index].id,
        id_proceso: this.data.data[index].id_proceso,
        fecha_proceso: this.data.data[index].fecha_proceso
      }
    })
      .then((data) => {

        this.data.data[index] = data.data[0];
        this.data.codigo = data.codigo;
        this.data.mensaje = data.mensaje;

      })
      .catch((e) => {

        this.data.mensaje = e;
      });



  }

  async retardo() {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(true)
      }, 2000);
    });
  }

  async lanzar(index, event) {
    let _request = { bit: 0 };

    event.target.setAttribute("disabled", "disabled");

    if (this.data.data[index].doc_params != undefined) {
      _request = JSON.parse(this.data.data[index].doc_params);
    }

    _request["id"] = this.data.data[index].id;

    if (this.data.data[index].id_proceso === 'poliza') {
      if (_request.bit != undefined && _request.bit == 1) {
        await this.service.sendPolizasBit(_request)
          .then((data) => {
            console.log(data);
            this.data.data[index].st_proc = data.st_proc;
            this.data.data[index].codigo = data.codigo;
            this.data.data[index].mensaje = data.mensaje;

          })
          .catch((e) => {
            this.data.mensaje = e;
          });


      } else {
        delete _request.bit;
        await this.service.sendPolizas(_request)
          .then((data) => {
            console.log(data);
            this.data.data[index].st_proc = data.st_proc;
            this.data.data[index].codigo = data.codigo;
            this.data.data[index].mensaje = data.mensaje;

          })
          .catch((e) => {
            this.data.mensaje = e;
          });
      }
    }

    if (this.data.data[index].id_proceso === 'pedido') {
      await this.service.sendPedidos(_request)
        .then((data) => {
          this.data.data[index].st_proc = data.st_proc;
          this.data.data[index].codigo = data.codigo;
          this.data.data[index].mensaje = data.mensaje;

        })
        .catch((e) => {
          this.data.mensaje = e;
        });
    }


    if (this.data.data[index].id_proceso === 'factura') {
      await this.service.sendFacturas(_request)
        .then((data) => {
          this.data.data[index].st_proc = data.st_proc;
          this.data.data[index].codigo = data.codigo;
          this.data.data[index].mensaje = data.mensaje;

        })
        .catch((e) => {
          this.data.mensaje = e;
        });
    }

    if (this.data.data[index].id_proceso === 'facturalock') {
      if (_request.bit != undefined && _request.bit == 1) {
        await this.service.sendFacturasLockBit(_request)
          .then((data) => {
            console.log(data);
            this.data.data[index].st_proc = data.st_proc;
            this.data.data[index].codigo = data.codigo;
            this.data.data[index].mensaje = data.mensaje;

          })
          .catch((e) => {
            this.data.mensaje = e;
          });
      } else {
        await this.service.sendFacturasLock(_request)
          .then((data) => {
            this.data.data[index].st_proc = data.st_proc;
            this.data.data[index].codigo = data.codigo;
            this.data.data[index].mensaje = data.mensaje;

          })
          .catch((e) => {
            this.data.mensaje = e;
          });

      }
    }
    event.target.removeAttribute("disabled");

  }

  async detener(index, event) {
    event.target.setAttribute("disabled", "disabled");
    console.log("Parando proceso..");
    let _request = {
      info: {
        tmov: "ST",
        id_proceso: this.data.data[index].id_proceso,
        id: this.data.data[index].id,
        fecha_proceso: this.data.data[index].fecha_proceso
      }
    }


    await this.service.setDetener(_request)
      .then((data) => {
        console.log("respuesta dete 2", data);



        /*
        this.data.data[index].pendiente=data.pendiente;
        this.data.data[index].st_proc=data.st_proc;
        this.data.data[index].detener=data.detener;
        this.data.data[index].codigo=data.codigo;
        this.data.data[index].mensaje=data.mensaje;
        
        
        this.info.procesado=data.procesado;
        this.info.procesando=data.procesando;
        this.info.error=data.error;
        this.info.corregir=data.corregir;
        
        */
        this.refresh = false;
      })
      .catch((e) => {

        this.data.mensaje = e;
      });

    /*Refrescar la informacion */

    await this.service.getDatosProceso({
      info: {
        tmov: 'SL',
        id: this.data.data[index].id,
        id_proceso: this.data.data[index].id_proceso,
        fecha_proceso: this.data.data[index].fecha_proceso
      }
    })
      .then((data) => {

        this.data.data[index] = data.data[0];
        this.data.codigo = data.codigo;
        this.data.mensaje = data.mensaje;

      })
      .catch((e) => {

        this.data.mensaje = e;
      });


    event.target.removeAttribute("disabled");
  }


  async eliminar(index) {

    console.log("Eliminando proceso..");
    let _request = {
      info: {
        tmov: "DL",
        id_proceso: this.data.data[index].id_proceso,
        id: this.data.data[index].id,
        fecha_proceso: this.data.data[index].fecha_proceso
      }
    }

    console.log("ii ", this.data.data);

    await this.service.setDelete(_request)
      .then((data) => {
        console.log("respuesta elimina 2", data);
        if (data.codigo == 200)
          this.data.data.splice(index, 1);
      })
      .catch((e) => {
        this.data.mensaje = e;
      });

    console.log("fin  ", this.data.data);
  }




  ngOnInit() {

  }

}
