import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { PolizaComponent } from './components/poliza/poliza.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ValeComponent } from './components/vale/vale.component';
import { FacturaComponent } from './components/factura/factura.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './components/home/home.component';
import { AutentificarService } from './services/autentificar.service';
import { ResetComponent } from './components/reset/reset.component';
import { FacturalockComponent } from './components/facturalock/facturalock.component';
import { ProcesoComponent } from './components/proceso/proceso.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    PolizaComponent,
    DashboardComponent,
    ValeComponent,
    FacturaComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    ResetComponent,
    FacturalockComponent,
    ProcesoComponent

    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
    

  ],
  providers: [AutentificarService],//global
  bootstrap: [AppComponent]
})
export class AppModule { }
