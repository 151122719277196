import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FacturaComponent } from './components/factura/factura.component';
import { FacturalockComponent } from './components/facturalock/facturalock.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { PolizaComponent } from './components/poliza/poliza.component';
import { ProcesoComponent } from './components/proceso/proceso.component';
import { ResetComponent } from './components/reset/reset.component';
import { ValeComponent } from './components/vale/vale.component';
import { AuthGuardGuard } from './services/auth-guard.guard';


const routes: Routes = [
  {path:'',children:[
    {path: 'home', component:HomeComponent,canActivate:[AuthGuardGuard]},
    {path: 'procesos', component: ProcesoComponent,canActivate:[AuthGuardGuard]},
    {path: 'facturalock', component: FacturalockComponent,canActivate:[AuthGuardGuard]},
    {path: 'poliza', component: PolizaComponent,canActivate:[AuthGuardGuard]},
    {path: 'vale', component: ValeComponent,canActivate:[AuthGuardGuard]},
    {path: 'factura', component:FacturaComponent,canActivate:[AuthGuardGuard]},
    {path: 'reset', component:ResetComponent,canActivate:[AuthGuardGuard]},
    {path:'',redirectTo:'',pathMatch:'full'}
  ]},
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {path: '**', component: LoginComponent,canActivate:[AuthGuardGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
