import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class interfacesSAP{

  config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  // url = 'http://172.16.161.133:3005';//Desarrollo

  url_front = 'http://10.10.250.153:3015';//Produccion
  url_front_auto = 'http://10.10.250.153:3016';//Produccion
  url_front_auto_bit = 'http://10.10.250.153:3023';//Produccion

 //url = 'http://10.10.250.153:3005';//Produccion
  /*url = 'http://10.10.250.152:3005';//Desarrollo
  url_front = 'http://10.10.250.152:3015';//Desarrollo
  */

  constructor(private http: HttpClient,private router:Router) {}



  async getDatosProceso(body){
    let resp={data:[],codigo:422,mensaje:'Error'};
  
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/proceso`, body, this.config).toPromise();
      resp.data=respuesta.data;
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){

      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }


      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }

  async setDetener(body){
    let resp={pendiente:0,procesado:0,procesando:0,error:0,corregir:0,st_proc:0,detener:0,codigo:422,mensaje:'Error'};
  
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));

    try{
      const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/proceso`, body, this.config).toPromise();

      console.log("resp dete ",respuesta);
     
      resp.pendiente=respuesta.pendiente;
      resp.procesado=respuesta.procesado;
      resp.procesando=respuesta.procesando;
      resp.error=respuesta.error;
      resp.corregir=respuesta.corregir;

      resp.st_proc=respuesta.st_proc;
      resp.detener=respuesta.detener;
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){

      if(error.error.codigo!=undefined){
        resp.pendiente=error.error.pendiente;
        resp.procesado=error.error.procesado;
        resp.procesando=error.error.procesando;
        resp.error=error.error.error;
        resp.corregir=error.error.corregir;
        resp.st_proc=error.error.st_proc;
        resp.detener=error.error.detener;
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }


      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }

  
  async setDelete(body){
    let resp={codigo:422,mensaje:'Error'};
  
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));

    try{
      const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/proceso`, body, this.config).toPromise();

      console.log("resp elimiar ",respuesta);
    
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){

      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }


      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }


  async getDatosPoliza(body){
    let resp={data:[],codigo:422,mensaje:'Error'};
  
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/polizas`, body, this.config).toPromise();
      resp.data=respuesta.data;
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){

      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }


      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }


  async sendPoliza(body){
    let resp={data:[],codigo:422,mensaje:'Error Interno',mensajes:[]};

    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/poliza/send`, body, this.config).toPromise();
      resp.data=respuesta.data;
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){
     // console.log("errrr ",error);
      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
        resp.mensajes=error.error.mensajes;
      }else{
        resp.codigo=404;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }

/*
  async sendPolizas_BK(body){
    let resp={data:[],procesados:0,codigo:422,mensaje:'Error'};


    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url }/api/isap/polizas/send`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }
*/
  async sendPolizas(body){
    let resp={data:[],id:'',st_proc:0,procesados:0,codigo:422,mensaje:'Error'};
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));

    try{
    const respuesta = await this.http.post<any>(`${ this.url_front_auto}/api/isap/polizas_auto/send`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.id=respuesta.id;
     resp.st_proc=respuesta.st_proc;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.st_proc=error.error.st_proc;
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }


  async sendPolizasBit(body){
    let resp={data:[],id:'',st_proc:0,procesados:0,codigo:422,mensaje:'Error'};
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));

    try{
    const respuesta = await this.http.post<any>(`${ this.url_front_auto_bit }/api/isap/polizas_bit/send`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.id=respuesta.id;
     resp.st_proc=respuesta.st_proc;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.st_proc=error.error.st_proc;
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }



  
  async getDatosPedidos(body){
    let resp={data:[],codigo:422,mensaje:'Error'};

    
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/pedido`, body, this.config).toPromise();
      resp.data=respuesta.data;
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){
  
      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error.message;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }


  async sendPedido(body){
    let resp={data:[],doc_response:{mensajes:[]},st_proc:0,codigo:422,mensaje:'Error Interno'};


    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
  //  console.log("cofig ped ",this.config);
    try{
        const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/pedido/send`, body, this.config).toPromise();
      //  console.log("repuesta ",respuesta);
        resp.doc_response=respuesta.doc_response;
        resp.st_proc=respuesta.st_proc;
        resp.data=respuesta.data;
        resp.codigo=respuesta.codigo;
        resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      resp.st_proc=44;

     // console.log("jeje ",error);

      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
        resp.doc_response=error.error.doc_response;
      }else{
        resp.codigo=404;
        resp.doc_response.mensajes.push({numero:resp.codigo,mensaje:error.message});
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }


    }

   // console.log("rrrr ",resp);

    return resp;
  }

  /*
  async sendPedidos_bk(body){
    let resp={data:[],procesados:0,codigo:422,mensaje:'Error'};

    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url }/api/isap/pedidos/send`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje= error.message;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }
 */

  
  async sendPedidos(body){
    let resp={data:[],id:'',st_proc:0,procesados:0,codigo:422,mensaje:'Error'};
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));

    try{
    const respuesta = await this.http.post<any>(`${ this.url_front_auto }/api/isap/pedidos_auto/send`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.id=respuesta.id;
     resp.st_proc=respuesta.st_proc;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.st_proc=error.error.st_proc;
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }



  
  
  async getDatosFactura(body){
    let resp={data:[],codigo:422,mensaje:'Error'};
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));

    try{
       const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/facturas`, body, this.config).toPromise();
      resp.data=respuesta.data;
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){
  
      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    }

    return resp;
  }

  async sendFactura(body){
    let resp={data:[],doc_response:{mensajes:[]},st_proc:0,codigo:422,mensaje:'Error Interno'};

    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url_front}/api/isap/factura/send`, body, this.config).toPromise();
      
    
       resp.doc_response=respuesta.doc_response;
       resp.st_proc=respuesta.st_proc;
      resp.data=respuesta.data;
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;

        resp.mensaje=error.error.mensaje;
        resp.doc_response=error.error.doc_response;
      }else{
        resp.codigo=404;
        resp.doc_response.mensajes.push({numero:resp.codigo,mensaje:error.message});
      }


      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }

  /*
  async sendFacturas_bk(body){
    let resp={data:[],procesados:0,codigo:422,mensaje:'Error'};

    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url }/api/isap/facturas/send`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }
 */

  
  async sendFacturas(body){
    let resp={data:[],id:'',st_proc:0,procesados:0,codigo:422,mensaje:'Error'};
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));

    try{
    const respuesta = await this.http.post<any>(`${ this.url_front_auto }/api/isap/facturas_auto/send`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.id=respuesta.id;
     resp.st_proc=respuesta.st_proc;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.st_proc=error.error.st_proc;
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }




  /*************************desbloqueo de facturas */

  async getDatosFacturaLock(body){
    let resp={data:[],codigo:422,mensaje:'Error'};
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));

    try{
       const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/facturas/lock`, body, this.config).toPromise();
      resp.data=respuesta.data;
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){
  
      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    }

    return resp;
  }

  async sendFacturaLock(body){
    let resp={data:[],doc_response:{mensajes:[]},st_proc:0,codigo:422,mensaje:'Error Interno'};

    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url_front }/api/isap/facturas/lock/desbloquear`, body, this.config).toPromise();
    
      resp.doc_response=respuesta.doc_response;
      resp.st_proc=respuesta.st_proc;
      resp.data=respuesta.data;
      resp.codigo=respuesta.codigo;
      resp.mensaje=respuesta.mensaje;
    }catch(error){
      console.log("entro al error ",error);
      if(error.error.codigo!=undefined){
        resp.data=error.error.data!=undefined ?error.error.data:[];
        resp.codigo=error.error.codigo;
        resp.st_proc=error.error.st_proc;
        resp.mensaje=error.error.mensaje;
        resp.doc_response=error.error.doc_response;
      }else{
        resp.codigo=404;
        resp.doc_response.mensajes.push({numero:resp.codigo,mensaje:error.message});
      }


      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }

  /*
  async sendFacturasLockBK(body){
    let resp={data:[],procesados:0,codigo:422,mensaje:'Error'};

    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url }/api/isap/facturas/locks/desbloquear`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }
 */
    
  async sendFacturasLock(body){
    let resp={data:[],id:'',st_proc:0,procesados:0,codigo:422,mensaje:'Error'};

    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    try{
       const respuesta = await this.http.post<any>(`${ this.url_front_auto }/api/isap/facturas_auto/locks/desbloquear`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.id=respuesta.id;
     resp.st_proc=respuesta.st_proc;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.st_proc=error.error.st_proc;
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }


  
  async sendFacturasLockBit(body){
    let resp={data:[],id:'',st_proc:0,procesados:0,codigo:422,mensaje:'Error'};
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));

    try{
    const respuesta = await this.http.post<any>(`${ this.url_front_auto_bit }/api/isap/facturas_bit/locks/desbloquear`, body, this.config).toPromise();
     resp.data=respuesta.data;
     resp.id=respuesta.id;
     resp.st_proc=respuesta.st_proc;
     resp.procesados=respuesta.procesados;
     resp.codigo=respuesta.codigo;
     resp.mensaje=respuesta.mensaje;
    }catch(error){
    
      if(error.error.codigo!=undefined){
        resp.st_proc=error.error.st_proc;
        resp.codigo=error.error.codigo;
        resp.mensaje=error.error.mensaje;
      }else{
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      }

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }

    
    }

    return resp;
  }





}



