import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutentificarService } from 'src/app/services/autentificar.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  data={codigo:422,mensaje:'Contraseña Incorrecta'};
  isActive=false;
  cargando=false;
  public version: string = '1.1.0';

  constructor(private service:AutentificarService,private router:Router,private element:ElementRef<HTMLInputElement>) { 
    this.element.nativeElement.focus();
  }

  async autentificar(params){
    if(this.isActive)
    return false;

    let _request = {
      action:'LG',
      usuario:params.elements["usuario"].value,
      password:params.elements["password"].value
    };


    this.cargando=true;

    if(_request.usuario=='' || _request.password==''){
      this.data.codigo=422;
      this.data.mensaje='Por favor ingrese los parametros.';
      this.isActive=true;
      this.cargando=false;
      return;
    }
  
    await this.service.getAutentificar(_request)
    .then( (data)=>{
     // console.log("sesss ",sessionStorage.getItem('token'));
      let tok=sessionStorage.getItem('token')||undefined;

     // console.log("autentificar ",tok);

      if(tok!=undefined){

          this.router.navigate(['/home'])
         .then(()=>{
          // this.service.setStatus(true);
           this.service.getUsuario2();
           
           // window.location.reload();
          }); 
      }else{
          this.data.codigo=data.codigo;
          this.data.mensaje=data.mensaje;
          this.isActive=true;
      }
      this.cargando=false;
    })
    .catch((e)=>{
      console.log("err ",e);
     // this.router.navigate(['/login']);
      this.data.codigo=500;
      this.data.mensaje=e;

      this.isActive=true;
    });
  }

  async Cerrar(){
    this.isActive=false;

  }

  ngOnInit() {
    this.isActive=false;
    this.cargando=false;
  }

}
