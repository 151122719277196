import { Component, OnInit } from '@angular/core';
import { interfacesSAP } from 'src/app/services/sap.service';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.css']
})
export class FacturaComponent implements OnInit {

  datos={pages:[],data:[]};
  data={data:[],procesados:0,codigo:422,mensaje:'Sin Cambios'};
  info={id_poliza:'',mensajes:[]};

  isActive=false;
  isActiveInfo=false;
  sending=false;
  renglon={};

  bandera=false;
  cargando=false;
  
  _tope=10;
  _pages=[];
  _totalPag=0;
  _lastPage=0;
  _pagActive=1;

  fecha='';
  sucursal=0;
  factura='';

  constructor(private service:interfacesSAP) { }

 async getDatos(obj){
  this.cargando=true;
  this.bandera=false;
  await this.service.getDatosFactura(obj)
    .then( (data)=>{
      console.log("resss ",data);

      this.data.data=data.data;
      this.data.codigo=data.codigo;
      this.data.mensaje=data.mensaje;

      this.cargando=false;
      this.bandera=true;
    

    })
    .catch((e)=>{

      this.data.mensaje=e;
    });
}

async getPolizas(params,frm2){
let _request = {
  sucursal:params.elements["sucursal"].value,
  fecha_contabilizacion:params.elements["fecha_factura"].value,
  id_poliza:params.elements["factura"].value,
  st_proc:params.elements["stt"].value
}

//console.log("request ",_request);

 await this.getDatos(_request);

 await this.getTable(frm2);

 if(this.data.codigo!=200){
  this.isActive=true;
 }
 
}

async enviaPoliza(index,event){
  event.target.children[0].removeAttribute("hidden");

  event.target.setAttribute("disabled","disabled");

  await this.service.sendFactura(this.datos.data[index])
                    .then( (data)=>{
                      let obj=data.data[0];

                      //console.log("resp evn ",obj);

                      this.datos.data[index].st_proc=obj.st_proc;
                      this.datos.data[index].codigo=obj.codigo;
                      this.datos.data[index].mensaje=obj.mensaje;
                      this.datos.data[index].mensajes=obj.mensajes;
                      this.datos.data[index].documento_sap=obj.documento_sap;
                      this.datos.data[index].doc_response=obj.doc_response;
                    })
                    .catch((e)=>{
                      this.datos.data[index].st_proc=99;
                      this.datos.data[index].mensaje='Error';
                      this.datos.data[index].mensajes=e;
                    });  

  event.target.children[0].setAttribute("hidden","hidden");

  event.target.removeAttribute("disabled");

}

async enviaPolizas(params){
  this.sending=true;
  let _request = {
    sucursal:params.elements["sucursal"].value,
    fecha_contabilizacion:params.elements["fecha_factura"].value,
    id_poliza:params.elements["factura"].value,
    st_proc:params.elements["stt"].value
  }



  await this.service.sendFacturas(_request)
                    .then( (data)=>{
                      this.data.procesados=data.procesados;
                      this.data.codigo=data.codigo;
                      this.data.mensaje=data.mensaje;

                    })
                    .catch((e)=>{
                      this.data.codigo=500;
                      this.data.mensaje=e;

                    });  

  this.sending=false;
  this.isActive=true;

}

async  viewInfo(index){

  if(this.datos.data[index].doc_response ==undefined)
  return;

  if(this.datos.data[index].doc_response.mensajes==undefined)
  return;

  this.info.id_poliza=this.datos.data[index].id_poliza;
  this.info.mensajes =this.datos.data[index].doc_response.mensajes;

  if(this.info.mensajes.length > 0)
      this.isActiveInfo=true;
  

}



async retardar(segundos){
return new Promise(function(resolve, reject) {
    setTimeout(function() {
      resolve(true)
      console.log("retardo de "+segundos/1000+" s");
    }, segundos);
  });
}



async getTable(size){

let pages=Math.ceil(this.data.data.length/size);

this._lastPage=0;
this._pages=[];
for(let i=1;i<=pages;i++){
  this._pages.push(i);
}

this._totalPag=Math.ceil(this._pages.length /this._tope);

await this.getPagina(1,size);
}

async getPagina(index,size){
  this._pagActive=index;
  index = Math.abs(parseInt(index));
  index = index > 0 ? index - 1 : index;
  size = parseInt(size);
  size = size < 1 ? 1 : size;

    let result= [...(this.data.data.filter((value, n) => {
        return (n >= (index * size)) && (n < ((index+1) * size))
    }))];

  this.datos.data=result;

  if(this._lastPage==0){
    await this.nextPaginas();
  }
/*
  let ul=document.getElementById("lista_pag");
  let lista=ul.getElementsByClassName("page-item active"); 

  for(var i=0;i< lista.length;i++){
    lista[i].setAttribute("class","page-item");
  }
*/

//  let ele=document.getElementById("paso"+(index+1));
 // ele.setAttribute("class","page-item active");



}

async nextPaginas(){
  let index=0;
  if(this._lastPage > this._totalPag){
    return;
  }

  if(this._lastPage < this._totalPag){
    this._lastPage++;
  }

  index=this._lastPage;
  index = index > 0 ? index - 1 : index;
  let size=this._tope;
  size = size < 1 ? 1 : size;
  let result= [...(this._pages.filter((value, n) => {
      return (n >= (index * size)) && (n < ((index+1) * size))
  }))];

  this.datos.pages=result;
}



async backPaginas(){
  let index=0;
  if(this._lastPage < 1){
    return;
  }

  if(this._lastPage > 1){
    this._lastPage--;
  }

  index=this._lastPage;
  index = index > 0 ? index - 1 : index;
 let size=this._tope;
  size = size < 1 ? 1 : size;

  let result= [...(this._pages.filter((value, n) => {
      return (n >= (index * size)) && (n < ((index+1) * size))
  }))];
  this.datos.pages=result;
}

async Cerrar(){
  this.isActive=false;
  this.isActiveInfo=false;
}




async descargacvs(){
let cvs=await this.ConvertToCSV();


if(cvs.trim()!='')
await this.saveFile(cvs);

}


async saveFile(data){
     // Convert the text to BLOB.
     var fecha=new Date();
     let fecha2= fecha.getFullYear() +''+ ('00'+(fecha.getMonth()+1)).slice(-2)+''+('00'+fecha.getDate()).slice(-2) ;
     

     const textToBLOB = new Blob([data], { type: 'text/plain' });
     const sFileName = 'FACTURAS_'+fecha2+'.csv';	   // The file to save the data.

     let newLink = document.createElement("a");
     newLink.download = sFileName;

    newLink.href = window.URL.createObjectURL(textToBLOB);
    newLink.style.display = "none";
    document.body.appendChild(newLink);

    /* if (window.webkitURL != null) {
         newLink.href = window.webkitURL.createObjectURL(textToBLOB);
     }
     else {
         newLink.href = window.URL.createObjectURL(textToBLOB);
         newLink.style.display = "none";
         document.body.appendChild(newLink);
     }*/
     newLink.click(); 
 }


 async ConvertToCSV() {
     var array =this.data.data; //typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     var str = '';

     if(array.length==0)
          return str;

      for (var index in array[0]) {
           if(index!=undefined){

             if (str != '') str += ';'

             str +=index;

             }
         }

      str += line + '\r\n';

     for (var i = 0; i < array.length; i++) {
         var line = '';
         for (var index in array[i]) {
             if (line != '') line += ';'

             line += array[i][index];
         }

         str += line + '\r\n';
     }

     return str;
 }


  ngOnInit() {

    var ff=new Date();
    this.fecha=('00'+(ff.getMonth()+1)).slice(-2) +'/'+('00'+ff.getDate()).slice(-2)+'/'+ff.getFullYear();
    this.sucursal=0;
    this.factura='';
  }

}
