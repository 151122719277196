import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutentificarService } from 'src/app/services/autentificar.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  data={codigo:422,mensaje:'Contraseña Incorrecta'};
  isActive=false;
  cargando=false;

  constructor(private service:AutentificarService,private router:Router) { }

  async reiniciar(params){
    let _request = {
      action:'LG',
      password_old:params.elements["password_old"].value,
      password:params.elements["password"].value,
      password2:params.elements["password2"].value
    };

    this.cargando=true;
  
    await this.service.setReset(_request)
    .then( (data)=>{
     
 
      this.data.codigo=data.codigo;
      this.data.mensaje=data.mensaje;
      this.cargando=false;

      if(data.codigo==200){
        this.router.navigate(['/logout'])
          .then(()=>{
          // window.location.reload();
          })
      }else{
        this.isActive=true;
      }

    })
    .catch((e)=>{
      console.log("err ",e);
     // this.router.navigate(['/login']);
      this.data.codigo=500;
      this.data.mensaje=e;

      this.isActive=true;
    });
  }

  
  async Cerrar(){
    this.isActive=false;

  }


  ngOnInit() {

  }

}
