import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  title = 'interfaces';
   tok=sessionStorage.getItem('token')||undefined;
  loggg=this.tok!=undefined?true:false;

 
}
