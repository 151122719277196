import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})


export class AutentificarService {
  private _user:Subject<any>=new Subject<any>();
  private _status:Subject<any>=new Subject<any>();
  public userObs=this._user.asObservable();
  public statusObs=this._status.asObservable();

  config = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')};
    //url = 'http://172.16.161.133:3005';//Desarrollo
   url_front = 'http://10.10.250.153:3015';//Produccion
   //url_front = 'http://10.10.250.152:3015';//Desarrollo

   constructor(private http:HttpClient,private router:Router) { }

  async getAutentificar(body){
    let resp={codigo:422,mensaje:'Error'};
    try{
        const respuesta = await this.http.post<any>(`${ this.url_front }/api/usuario/autentificar`, body, this.config).toPromise();
       
        //console.log("resp ",respuesta);
        if(respuesta.token!=undefined){
          sessionStorage.setItem('token',respuesta.token);
          resp.codigo=200;
          resp.mensaje='OK';
        }else{
          sessionStorage.clear();
          resp.codigo=respuesta.codigo;
          resp.mensaje=respuesta.mensaje;
        }
        
    }catch(error){
      sessionStorage.clear();
        console.log("err ",error.message);
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error.message !=undefined?error.message:error;
      
    
    }

    return resp;
  }


  async getUsuario(){
    let resp={usuario:{},codigo:422,mensaje:'Error'};

    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    
    //console.log("config ",this.config);
    try{
        const respuesta = await this.http.post<any>(`${ this.url_front }/api/usuario`, {}, this.config).toPromise();
        //console.log("respp1 ",respuesta);
        resp.usuario=respuesta.info;
        resp.codigo=respuesta.codigo;
        resp.mensaje=respuesta.mensaje;

    }catch(error){
      //console.log("err ",error);
      
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      
    
    }

    return resp;
  }


  async getUsuario2(){
    
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
   // console.log("config 2",this.config);

        return await this.http.post<any>(`${ this.url_front }/api/usuario`, {}, this.config).subscribe(res=>{
         // console.log("respuesta usuario ",res);
          this.setStatus(true); 
         
          //sessionStorage.setItem('permisos',JSON.stringify(res.info.permisos));
          this._user.next(res)
         
        },
        err=>{
          console.log("errror ",err);

          if(err.status==403 || err.status==0){
            this.router.navigate(['/logout'])
            .then(()=>{
             // window.location.reload();
            })
          }
        

        });

  }

  
  async setReset(body){
    let resp={usuario:{},codigo:422,mensaje:'Error'};
    this.config.headers=new HttpHeaders().set('authorization', sessionStorage.getItem('token'));
    
   // console.log("config  reset",this.config);
    try{
        const respuesta = await this.http.post<any>(`${ this.url_front }/api/usuario/reset`, body, this.config).toPromise();
        //console.log("respp1 ",respuesta);
        resp.usuario=respuesta.info;
        resp.codigo=respuesta.codigo;
        resp.mensaje=respuesta.mensaje;

    }catch(error){
      console.log("err ",error);

      if(error.status==403){
        this.router.navigate(['/logout'])
        .then(()=>{
         // window.location.reload();
        })
      }
      
        resp.codigo=500;
        resp.mensaje='Error interno , ' + error;
      
    
    }

    return resp;
  }


  async setStatus(valor){
    return this._status.next(valor);
  }


}
