import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { Usuario } from 'src/app/models/usuario';
import { AutentificarService } from 'src/app/services/autentificar.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  
  status:boolean=false;
  usuario:any;
  constructor(private service:AutentificarService,private router:Router) { 


  }

  async getUsuario(){
    await this.service.getUsuario2()
    .then( (data)=>{})
    .catch((e)=>{
      console.log("err1 ",e);
    });
  }

  async clickEvent(event){
    let _a=event.target.parentElement.parentElement;
    let ul=document.getElementById("navbar");
    let lista=ul.getElementsByClassName("nav-item"); 
    for(var i=0;i< lista.length;i++){
      lista[i].setAttribute("class","nav-item ");
    }
   _a.setAttribute("class","nav-item active");
  }

  ngOnInit() {
    
    let tok=sessionStorage.getItem('token')||undefined;


      if(tok!=undefined){
          this.getUsuario();
      }else{
        sessionStorage.clear();
        this.status=false;
        this.router.navigate(['/login']);
      }
      
      this.service.statusObs.subscribe((res)=>{
        this.status=res;
      });

      this.service.userObs.subscribe((res)=>{
        this.usuario=res.info;
      });

     
  }

}
